// fas icons
import {
  faBars,
  faPlus,
  faMinus,
  faXmark,
  faArrowRight,
  faSpinner,
  faMagnifyingGlass,
  faPencil,
  faHammer,
  faPersonDigging,
  faHelmetSafety,
  faLocationDot,
  faTrash,
  faBoltLightning,
  faPuzzlePiece,
  faDollarSign,
  faPhone
} from "@fortawesome/free-solid-svg-icons";

// far icons
// import {
  
// } from "@fortawesome/free-regular-svg-icons";

// fab icons
import { 
  faFacebook,
  faInstagram
} from '@fortawesome/free-brands-svg-icons' 

import { library } from "@fortawesome/fontawesome-svg-core";

const Icons = () => {
  return library.add(
     faBars,
     faPlus,
     faMinus,
     faXmark,
     faArrowRight,
     faSpinner,
     faMagnifyingGlass,
     faPencil,
     faHammer,
     faPersonDigging,
     faHelmetSafety,
     faFacebook,
     faInstagram,
     faLocationDot,
     faTrash,
     faBoltLightning,
     faPuzzlePiece,
     faDollarSign,
     faPhone
  );
};

export default Icons;