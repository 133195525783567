import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react'
import SideNavContext from "../../Contexts/SideNavContext";

const Navbar = () => {
  const {
    setIsSideNavOpen
  } = useContext(SideNavContext);

  const handleOpeningSideNav = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.top = `-${window.pageYOffset}px`;
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    setIsSideNavOpen(true);
  }

  return (
    <div className="navbar-wrapper">
      <div className="menu-toggle" onClick={handleOpeningSideNav}>
        <FontAwesomeIcon icon={['fas', 'bars']} />
      </div>
    
      <div className="logo-wrapper">
        E&O Landscape
      </div>

      <a 
        className="top-nav-call-us-toggle"
        href="tel:+14359629535"
      >
        <div className="top-nav-call-us-text">
          placeholder
        </div>

        <div
          className="top-nav-call-us-icon top-nav-call-us-icon-animation"  
        >
          <FontAwesomeIcon icon={['fas', 'phone']} /> 
        </div>
      </a>
    </div>
  )
}

export default Navbar;