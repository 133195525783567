import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import SideNavContext from "../../Contexts/SideNavContext";
import { auth } from "../../Config/firebase";

const SideNav = () => {
  const { isSideNavOpen, setIsSideNavOpen } = useContext(SideNavContext);

  useEffect(() => {
    const mobileSideNavWrapperElement =
      document.getElementById("side-nav-wrapper");
    const mobileSideNavMainContentWrapperElement = document.getElementById(
      "side-nav-main-content"
    );
    const mobileSideNavFooterWrapperElement = document.getElementById(
      "side-nav-socials-wrapper"
    );

    if (
      isSideNavOpen &&
      !mobileSideNavWrapperElement.classList.contains("side-nav-slide")
    ) {
      setTimeout(() => {
        mobileSideNavWrapperElement.classList.toggle("side-nav-slide");
        mobileSideNavMainContentWrapperElement.classList.toggle(
          "side-nav-main-content-slide"
        );
        mobileSideNavFooterWrapperElement.classList.toggle(
          "side-nav-footer-slide"
        );
      }, 50)
    }
  }, [isSideNavOpen]);

  const handleCollapsableLinkClick = (innerContentId, spinElem1, spinElem2) => {
    const el = document.getElementById(innerContentId);
    const el2 = document.getElementById(spinElem1);
    const el3 = document.getElementById(spinElem2);
    el.classList.toggle("open-content");
    el2.classList.toggle("spin-one");
    el3.classList.toggle("spin-two");
  };


  const handleCloseMobileSideNav = () => {
    const mobileSideNavWrapperElement =
      document.getElementById("side-nav-wrapper");
      mobileSideNavWrapperElement.classList.toggle("side-nav-slide");

      setTimeout(() => {
        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('top');
        document.body.style.removeProperty('width');
        setIsSideNavOpen(false);
      }, 500);
  };

  const handleLoggingOut = () => {
    return auth.signOut();
  }

  if (!isSideNavOpen) {
    return <></>;
  }

  return (
    <div className="side-nav-container">
      <div id="side-nav-wrapper" className="side-nav-wrapper">
        <div className="side-nav-heading-wrapper">
          <div
            className="side-nav-close-modal-header"
            onClick={() => handleCloseMobileSideNav(false)}
          >
            <svg
              style={{ width: "100%", height: "100%" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
        </div>

        <div id="side-nav-main-content" className="side-nav-main-content">
          <div className="side-nav-main-links-wrapper">
            <div className="side-nav-link-wrapper">
              <NavLink
                className="side-nav-link"
                exact
                to="/"
                onClick={handleCloseMobileSideNav}
              >
                Home
              </NavLink>
            </div>

            <div className="side-nav-link-wrapper">
              <NavLink
                className="side-nav-link"
                to="/about-us"
                onClick={(e) => handleCloseMobileSideNav(e, false, true)}
              >
                About Us
              </NavLink>
            </div>

            <div
              id="side-nav-services-link-container"
              className="side-nav-link-wrapper side-nav-services-link-container"
            >
              <div
                className="side-nav-services-link-header-wrapper"
                onClick={() =>
                  handleCollapsableLinkClick(
                    "side-nav-services-link-container",
                    "side-nav-collapsable-link-icon-one",
                    "side-nav-collapsable-link-icon-two"
                  )
                }
              >
                <div className="side-nav-services-link-header">
                  Services
                </div>

                <div className="side-nav-services-link-header-icons-wrapper">
                  <div id="side-nav-collapsable-link-icon-one" className="side-nav-services-link-header-icon-wrapper">
                    <FontAwesomeIcon icon={['fas', 'minus']} />
                  </div>

                  <div id="side-nav-collapsable-link-icon-two" className="side-nav-services-link-header-icon-wrapper side-nav-services-link-header-icon-two-wrapper">
                    <FontAwesomeIcon icon={['fas', 'minus']} />
                  </div>
                </div>
              </div>

              <div className="side-nav-services-links-wrapper">
                <NavLink 
                  className="side-nav-services-link-wrapper"
                  exact
                  to="/services/design-and-build"
                  onClick={handleCloseMobileSideNav}
                >
                  Design & Build
                </NavLink>

                <NavLink 
                  className="side-nav-services-link-wrapper"
                  exact
                  to="/services/rockwork-and-excavation"
                  onClick={handleCloseMobileSideNav}
                >
                  Rock Work & Excavation
                </NavLink>

                <NavLink 
                  className="side-nav-services-link-wrapper"
                  exact
                  to="/services/outdoor-construction-and-landscaping"
                  onClick={handleCloseMobileSideNav}
                >
                  Outdoor Construction & Landscaping
                </NavLink>

                <NavLink 
                  className="side-nav-services-link-wrapper"
                  exact
                  to="/services/maintenance-and-snow-removal"
                  onClick={handleCloseMobileSideNav}
                >
                  Maintenance & Snow Removal
                </NavLink>
              </div>
            </div>

            <div className="side-nav-link-wrapper">
              <NavLink
                className="side-nav-link"
                to="/book-consultation"
                onClick={(e) => handleCloseMobileSideNav(e, false, true)}
              >
                Book Consultation
              </NavLink>
            </div>
          </div>

          <div className="side-nav-secondary-links-wrapper">
            <div className="side-nav-link-wrapper">
              <NavLink 
                className="side-nav-link"
                to="/financing"
                onClick={handleCloseMobileSideNav}
              >
                Financing
              </NavLink>
            </div>

            <div className="side-nav-link-wrapper">
              <NavLink 
                className="side-nav-link"
                to="/photo-gallery"
                onClick={handleCloseMobileSideNav}
              >
                Photo Gallery
              </NavLink>
            </div>

            {
              auth.currentUser ? (
                <div className="side-nav-link-wrapper">
                  <NavLink 
                    className="side-nav-link"
                    to="/photo-gallery-form"
                    onClick={handleCloseMobileSideNav}
                  >
                    Project Gallery Form
                  </NavLink>
                </div>
              ) : null
            }

            <div className="side-nav-link-wrapper">
              <a 
                href="tel:+14359629535"
                onClick={handleCloseMobileSideNav} 
                className="side-nav-link"
              >
                <FontAwesomeIcon style={{ transform: "rotate(45deg)"}} icon={["fas", "phone" ]} /> 435-962-9535
              </a>
            </div>

            {
              !auth.currentUser ? (
                <div className="side-nav-link-wrapper">
                  <NavLink 
                    className="side-nav-link"
                    to="/login"
                    onClick={handleCloseMobileSideNav}
                  >
                    Login
                  </NavLink>
                </div>
              ) : null
            }

            {
              auth.currentUser ? (
                <div className="side-nav-link-wrapper">
                  <div 
                    onClick={handleLoggingOut} 
                    className="side-nav-link"
                  >
                    Logout
                  </div>
                </div>
              ) : null
            }
          </div>
        </div>

        <div id="side-nav-socials-wrapper" className="side-nav-socials-wrapper">
          <a 
            target="_blank"
            href="https://www.facebook.com/EandO.Landscape?mibextid=LQQJ4d"
            className="side-nav-social-link"
          >
            <FontAwesomeIcon icon={["fab", "facebook" ]} />
          </a>

          <a 
            target="_blank"
            href="https://instagram.com/eando.landscape?igshid=YmMyMTA2M2Y="
            className="side-nav-social-link"
          >
            <FontAwesomeIcon icon={["fab", "instagram" ]} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SideNav;